












































import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { CaseAnalysisCategory, CaseAnalysisReport } from '@/components/case-analysis/types'

@Component
export default class CategoryComponent extends Vue {
  @Prop() private category: CaseAnalysisCategory | undefined

  @Inject() private eventBus!: Vue

  selectReport (report: CaseAnalysisReport): void {
    this.eventBus.$emit('selectReport', report)
  }
}
