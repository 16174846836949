
import { Component, Prop, Vue } from 'vue-property-decorator'
import DownloadButton from '@/components/checkout/base/DownloadButton.vue'
import axios from 'axios'

@Component({
  components: { DownloadButton }
})
export default class SendReceipts extends Vue {
  @Prop({ default: () => [] }) private stateInfo: any | undefined
  @Prop({ default: () => [] }) private nextView: any | undefined

  private buttonText = 'Belegliste ausdrucken'
  private completed = false // documentsSent from filingState

  created () {
    if (this.nextView === 'send') {
      this.completed = this.stateInfo.receiptsOk
    } else {
      this.completed = this.stateInfo.documentsSent
    }
    this.$store.state.nextSectionEnabled = this.completed
  }

  updated () {
    if (this.completed) {
      this.setDocumentsSent(this.completed)
    }
    this.$store.state.nextSectionEnabled = this.completed
  }

  setDocumentsSent (completed: boolean) {
    let parameterName = ''
    if (this.nextView === 'send') {
      parameterName = 'receiptsOk'
    } else {
      parameterName = 'sent'
    }

    axios.post('/elster/saveInterviewSessionParam?' + parameterName + '=' + (completed ? 'true' : 'false'), {}, {})
      // eslint-disable-next-line no-console
      .catch(errors => console.warn(errors))
  }
}
