


































import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { EvaluationHomeResponse, SpreadsheetData } from '@/components/case-analysis/types'
import Accordion from '@/components/ui/Accordion.vue'
import AccordionItem from '@/components/ui/AccordionItem.vue'
import CategoryComponent from '@/components/case-analysis/CategoryComponent.vue'
@Component({
  components: { Accordion, AccordionItem, CategoryComponent }
})
export default class CaseAnalysisComponent extends Vue {
  @Prop() private response: EvaluationHomeResponse | undefined
  @Prop({ default: () => [] }) private sheets!: SpreadsheetData[]

  @Inject() private eventBus!: Vue

  get allSheets (): SpreadsheetData[] {
    return this.sheets.concat((this.response?.euerSheets) || [])
  }

  selectSheet (sheet: SpreadsheetData) {
    this.eventBus.$emit('selectSheet', sheet)
  }
}
