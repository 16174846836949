import vuetify from './plugins/vuetify'
import CaseAnalysisApp from '@/widgets/CaseAnalysisApp.vue'
import CaseListApp from '@/widgets/CaseListApp.vue'
// Global styles
import '@/assets/_global.scss'
import '@fortawesome/fontawesome-free/css/all.css'
import { CaseAnalysisAppProps } from '@/components/case-analysis/types'
import { CaseListProps } from '@/components/case-list/types'
import CheckOutApp from '@/widgets/CheckOutApp.vue'
import PrintFilingDocuments from '@/widgets/PrintFilingDocuments.vue'
import PostFilingDocumentsAndReceipts from '@/widgets/PostFilingDocumentsAndReceipts.vue'
import FinalizedFiling from '@/widgets/FinalizedFiling.vue'
import VueRouter from 'vue-router'
import Routes from '@/routes'
import Vue from 'vue'
import { store } from '@/vue-store'
import PreviewApp from '@/widgets/PreviewApp.vue'
import FilingIntroductionApp from '@/widgets/FilingIntroductionApp.vue'
import SignFilingDocs from '@/widgets/SignFilingDocs.vue'

Vue.use(VueRouter)

Vue.config.ignoredElements = ['checkout-component']

export const router = new VueRouter({
  mode: 'abstract',
  base: process.env.BASE_URL + '/app' + window.location.hash,
  routes: Routes
})

function makeCaseAnalysisApp (
  props: CaseAnalysisAppProps
): {
  app: Vue;
  eventBus: Vue;
} {
  const eventBus = new Vue()
  const app = new Vue({
    vuetify,
    provide: { eventBus },
    render: function (h) {
      return h(CaseAnalysisApp, {
        props
      })
    }
  })
  return {
    eventBus,
    app
  }
}

function makeFilingIntroductionApp (props: {}): {
  app: Vue;
} {
  const app = new Vue({
    vuetify,
    render: function (h) {
      return h(FilingIntroductionApp, {
        props
      })
    }
  })
  return {
    app,
  }
}

function makePreviewApp (props: {}): {
  app: Vue;
} {
  const app = new Vue({
    vuetify,
    render: function (h) {
      return h(PreviewApp, {
        props
      })
    }
  })
  return {
    app,
  }
}

function makeCheckOutApp (props: {}): {
  app: Vue;
} {
  const app = new Vue({
    vuetify,
    store: store,
    router,
    render: function (h) {
      return h(CheckOutApp, {
        props
      })
    }
  })
  return {
    app,
  }
}

function makePrintFilingDocumentsApp (props: {}): {
  app: Vue;
} {
  const app = new Vue({
    vuetify,
    store: store,
    render: function (h) {
      return h(PrintFilingDocuments, {
        props,
      })
    },
  })
  return {
    app,
  }
}

function makePostFilingDocumentsAndReceiptsApp (props: {}): {
  app: Vue;
} {
  const app = new Vue({
    vuetify,
    store: store,
    render: function (h) {
      return h(PostFilingDocumentsAndReceipts, {
        props,
      })
    },
  })
  return {
    app,
  }
}

function makeSignFilingDocumentsApp (props: {}): {
  app: Vue;
} {
  // @ts-ignore
  const app = new Vue({
    vuetify,
    store: store,
    render: function (h) {
      return h(SignFilingDocs, {
        props
      })
    }
  })
  return {
    app
  }
}

function makeFinalizedFilingApp (props: {}): {
  app: Vue;
} {
  const app = new Vue({
    vuetify,
    store: store,
    render: function (h) {
      return h(FinalizedFiling, {
        props,
      })
    },
  })
  return {
    app,
  }
}

function makeCaseListApp (
  props: CaseListProps
): {
  app: Vue;
} {
  // @ts-ignore
  const app = new Vue({
    vuetify,
    render: function (h) {
      return h(CaseListApp, {
        props
      })
    }
  })
  return {
    app
  }
}

export default {
  makeCaseAnalysisApp,
  makeCaseListApp,
  makeFilingIntroductionApp,
  makePreviewApp,
  makeCheckOutApp,
  makePrintFilingDocumentsApp,
  makePostFilingDocumentsAndReceiptsApp,
  makeFinalizedFilingApp,
  makeSignFilingDocumentsApp
}
