
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import { VerificationMethod } from '@/components/checkout/types'
import VerificationInfoDialog from '@/components/checkout/verification/VerificationDialog.vue'

@Component({
  computed: {
    ...mapGetters({
      getCheckoutComponents: 'getCheckoutComponents',
      getPaymentData: 'getPaymentData'
    }),
    ...mapActions({
      updateCurrentFilingStepIndex: 'updateCurrentFilingStepIndex',
      updateCurrentStep: 'updateCurrentStep'
    })
  },
  components: {
    VerificationDialog: VerificationInfoDialog
  }
})
export default class NavigationButtons extends Vue {
  @Prop() private components: any[] | undefined
  @Prop({ default: () => [] }) private yuiSections: any | undefined

  private getCheckoutComponents!: any;

  private nextNavigationStep: any = ''

  private showNextButton = false;

  created () {
    this.displayNextButton()
  }

  back () {
    window.history.go(-1)
  }

  nextStep (back = false) {
    if (!this.getCheckoutComponents) {
      return
    }
    if ((this.$store.state.currentStep === 'finApi' || this.$store.state.currentStep === 'webId') && back) {
      this.$store.dispatch('updateCurrentStep', 'verification-overview')
      return
    }
    const currentStep = this.getCheckoutComponents.find((item: string) => {
      return item === this.$router.currentRoute.name
    })
    const currentIndex = this.getCheckoutComponents.indexOf(currentStep) + 1
    const nextStepIndex = back
      ? this.getCheckoutComponents.indexOf(currentStep) - 1
      : currentIndex
    const nextStep = this.getCheckoutComponents[nextStepIndex]
    if (nextStep === 'checkout-web-component' && back) {
      this.loadYuiSection(this.yuiSections.current, true)
      return
    }
    this.nextNavigationStep = nextStep

    this.$store.dispatch('updateCurrentStep', nextStep)
    const filingStepIndex = back
      ? this.$store.state.currentFilingStepIndex - 1
      : currentIndex
    this.$store.dispatch('updateCurrentFilingStepIndex', filingStepIndex)
    if (nextStep !== undefined && nextStepIndex >= 0) {
      this.$router.replace({
        name: nextStep
      })
    } else {
      if (nextStep === undefined) {
        this.loadYuiSection(this.yuiSections.previous, true)
      } else {
        this.back()
      }
    }
  }

  loadNext () {
    if (this.$store.state.currentStep === 'verification-overview') {
      if (this.$store.state.paymentData?.verification.verificationMethod === 'UNKNOWN' ||
        this.$store.state.paymentData?.verification.verificationMethod === undefined) {
        switch (this.$store.state.verificationData?.chosenVerificationMethod) {
          case 'webId':
            this.$store.dispatch('updateCurrentStep', 'webId')
            break
          case 'finApi':
            this.$store.dispatch('updateCurrentStep', 'finApi')
            break
          default:
            break
        }
      } else {
        this.nextStep()
      }
    } else if (this.$store.state.currentStep === 'checkout-filing-info') {
      this.$store.dispatch('updateCurrentStep', 'checkout-filing-transfer-data')
      this.$router.push({ name: 'checkout-filing-transfer-data' })
    } else if (this.$store.state.currentStep === 'checkout-filing-transfer-data') {
      this.$router.push({ name: 'checkout-filing-transfer-data' })
    } else if (this.$store.state.currentStep === '' || this.$store.state.filingResponse !== null) {
      this.loadYuiSection(this.yuiSections.next, true)
    } else {
      this.nextStep()
    }
  }

  loadYuiSection (sectionHash: string, reload = false) {
    window.location.hash = sectionHash
    if (reload) {
      window.location.reload()
    }
  }

  openInNewTab () {
    // noinspection TypeScriptValidateTypes
    const webIdUrl = this.$store.state.verificationData?.verificationMethods.find(
      (item: VerificationMethod) => {
        if (
          item.name ===
          this.$store.state.verificationData?.chosenVerificationMethod
        ) {
          return item
        }
      }
    ).url

    window.open(webIdUrl, '_blank', 'noreferrer')
  }

  updated () {
    this.displayNextButton()
  }

  displayNextButton () {
    const componentsWithNavigation = [
      'verification-overview',
      'payment-overview',
      'checkout-filing-info',
      'checkout-filing-transfer-data',
      ''
    ]
    if (componentsWithNavigation.includes(this.$store.state.currentStep as string)) {
      this.showNextButton = true
    } else {
      this.showNextButton = false
    }
  }
}
