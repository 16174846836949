
import { Vue, Component, Prop } from 'vue-property-decorator'
import { YuiSections } from '@/components/checkout/types'
import SignDocuments from '@/components/filing-documents/documents/sign/SignDocuments.vue'
import NavigationButtons from '@/components/filing-documents/navigation/NavigationButtons.vue'
import FilingNotCompleted from '@/components/filing-documents/FilingNotCompleted.vue'
import DoNotSignDocuments from '@/components/filing-documents/documents/sign/DoNotSignDocuments.vue'

@Component({
  components: { NavigationButtons, SignDocuments, FilingNotCompleted, DoNotSignDocuments }
})
export default class SignFilingDocs extends Vue {
  @Prop({ default: () => [] }) private documentsSigned: any | undefined
  @Prop({ default: () => [] }) private filingMethod: any | undefined
  @Prop({ default: () => [] }) private notYetSentViaElster: any | undefined
  @Prop({ default: () => [] }) private yuiSections: any | YuiSections | undefined

  private nextButtonId = 'ui-go-to-filing2-receipts'
  private showNext = this.documentsSigned

  created () {
    if (this.filingMethod === 'ELSTER_I') {
      this.showNext = this.documentsSigned
    } else {
      this.showNext = true
    }
  }
}
