
import { Component, Prop, Vue } from 'vue-property-decorator'
import { YuiSections } from '@/components/checkout/types'

@Component({
  computed: {
  },
})
export default class NavigationButtons extends Vue {
  @Prop() private yuiSections: any | YuiSections | undefined
  @Prop() private showNext: any | undefined
  @Prop() private nextButtonId: any | undefined

  created () {
    this.$store.state.nextSectionEnabled = this.showNext
  }

  loadNext () {
    if (this.$store.state.nextSectionEnabled) {
      this.loadYuiSection(this.yuiSections.next)
    }
  }

  loadYuiSection (sectionHash: string, reload = false) {
    window.location.hash = sectionHash
    if (reload) {
      window.location.reload()
    }
  }

  goBack () {
    window.history.back()
  }
}
