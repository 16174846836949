
import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'

@Component({
  components: {}
})
export default class SignDocuments extends Vue {
  @Prop({ default: () => [] }) private documentsSigned: any | undefined
  private signed = false

  created () {
    this.signed = this.documentsSigned
    this.$store.state.nextSectionEnabled = this.signed
  }

  updated () {
    if (this.signed) {
      this.setDocumentsPrinted(this.signed)
    }

    this.$store.state.nextSectionEnabled = this.signed
  }

  setDocumentsPrinted (signed: boolean) {
    axios.post('/elster/saveInterviewSessionParam?signed=' + (signed ? 'true' : 'false') + '&viewed=' + (signed ? 'true' : 'false'), {}, {})
      // eslint-disable-next-line no-console
      .catch(errors => console.warn(errors))
  }
}
