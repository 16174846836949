








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Tab extends Vue {
  @Prop() private title!: string;
}
