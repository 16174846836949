import { render, staticRenderFns } from "./DownloadDocuments.vue?vue&type=template&id=7602f2d4&"
import script from "./DownloadDocuments.vue?vue&type=script&lang=ts&"
export * from "./DownloadDocuments.vue?vue&type=script&lang=ts&"
import style0 from "./DownloadDocuments.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCardActions,VCardText,VCol,VContainer,VRow,VSheet})
