
import { Vue, Component } from 'vue-property-decorator'
import axios from 'axios'

@Component({})
export default class DoNotSignDocuments extends Vue {
  created () {
    this.setDocumentsSigned(true)
    this.$store.state.nextSectionEnabled = true
  }

  setDocumentsSigned (signed: boolean) {
    const parameterName = 'signed'

    axios.post('/elster/saveInterviewSessionParam?' + parameterName + '=' + (signed ? 'true' : 'false'), {}, {})
      // eslint-disable-next-line no-console
      .catch(errors => console.warn(errors))
  }
}
