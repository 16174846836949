
import { Vue, Component, Prop } from 'vue-property-decorator'
import PrintDocuments from '@/components/filing-documents/documents/print/PrintDocuments.vue'
import { YuiSections } from '@/components/checkout/types'
import NavigationButtons from '@/components/filing-documents/navigation/NavigationButtons.vue'
import DownloadButton from '@/components/checkout/base/DownloadButton.vue'
import FilingNotCompleted from '@/components/filing-documents/FilingNotCompleted.vue'

@Component({ components: { PrintDocuments, NavigationButtons, DownloadButton, FilingNotCompleted } })
export default class PrintFilingDocuments extends Vue {
  @Prop({ default: () => [] }) private stateInfo: any | undefined

  @Prop({ default: () => [] }) private yuiSections: any | YuiSections | undefined

  @Prop({ default: () => [] }) private tokenName: any | undefined
  @Prop({ default: () => [] }) private csrfToken: any | undefined

  private nextButtonId = 'ui-go-to-filing2-sign'
  private stepCompleted = this.stateInfo.documentsPrinted

  created () {
    if (this.stateInfo.elster2MailSendingNecessary) {
      this.stepCompleted = this.stateInfo.documentsPrinted ? this.stateInfo.documentsPrinted : false
    }

    if (this.stateInfo.filingMethod === 'ELSTER_II' && this.stateInfo.receiptsList.length <= 0) {
      this.stepCompleted = true
    }
    this.$store.state.nextSectionEnabled = this.stepCompleted
  }
}
