
import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import DownloadDocuments from '@/components/filing-documents/documents/print/DownloadDocuments.vue'

@Component({ components: { DownloadDocuments } })
export default class NothingToSend extends Vue {
  @Prop({ default: () => [] }) private tokenName: any | undefined
  @Prop({ default: () => [] }) private csrfToken: any | undefined

  created () {
    this.setDocumentsPrinted(true)
  }

  setDocumentsPrinted (printed: boolean) {
    this.$store.state.nextSectionEnabled = printed
    axios.post('/elster/saveInterviewSessionParam?printed=' + (printed ? 'true' : 'false'), {}, {})
      // eslint-disable-next-line no-console
      .catch(errors => console.warn(errors))
  }
}
