
import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'
import Alert from '@/components/checkout/base/Alert.vue'

@Component({ components: { Alert } })
export default class DoNotSendReceipts extends Vue {
  @Prop() private stateInfo: any | undefined
  @Prop() private currentStep: any | undefined

  created () {
    this.setDocumentsSent(true)
  }

  setDocumentsSent (stepCompleted: boolean) {
    let param = ''
    if (this.currentStep === 'receipts') {
      param = 'receiptsOk'
    } else {
      param = 'sent'
    }
    this.$store.state.nextSectionEnabled = stepCompleted
    axios.post('/elster/saveInterviewSessionParam?' + param + '=' + (stepCompleted ? 'true' : 'false'), {}, {})
      // eslint-disable-next-line no-console
      .catch(errors => console.warn(errors))
  }
}
