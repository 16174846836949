
import { Vue, Component, Prop } from 'vue-property-decorator'
import SendReceipts from '@/components/filing-documents/documents/send/SendReceipts.vue'
import DoNotSendReceipts from '@/components/filing-documents/documents/send/DoNotSendReceipts.vue'
import FilingNotCompleted from '@/components/filing-documents/FilingNotCompleted.vue'
import { YuiSections } from '@/components/checkout/types'
import NavigationButtons from '@/components/filing-documents/navigation/NavigationButtons.vue'
import DownloadButton from '@/components/checkout/base/DownloadButton.vue'

@Component({
  components: { NavigationButtons, SendReceipts, DoNotSendReceipts, FilingNotCompleted, DownloadButton },
})
export default class PostFilingDocumentsAndReceipts extends Vue {
  @Prop({ default: () => [] }) private stateInfo: any | undefined

  @Prop({ default: () => [] }) private yuiSections: any | YuiSections | undefined
  @Prop({ default: () => [] }) private nextView: any | undefined

  @Prop({ default: () => [] }) private tokenName: any | undefined
  @Prop({ default: () => [] }) private csrfToken: any | undefined

  private nextButtonId = 'ui-go-to-filing2-' + this.nextView
  private currentStep = ''

  created () {
    let sectionCompleted = false
    if (this.yuiSections.current.includes('receipts')) {
      sectionCompleted = this.stateInfo.receiptsOk
      this.currentStep = 'receipts'
    }
    if (this.yuiSections.current.includes('send')) {
      sectionCompleted = this.stateInfo.documentsSent
      this.currentStep = 'send'
    }
    if (this.stateInfo.filingMethod !== 'ELSTER_I') {
      if (this.stateInfo.receiptsList.length <= 0) {
        sectionCompleted = true
      }
    }
    this.$store.state.nextSectionEnabled = sectionCompleted
  }
}
