
import { Component, Prop, Vue } from 'vue-property-decorator'
import { YuiSections } from '@/components/checkout/types'

@Component({
  computed: {},
})
export default class NavigationButtons extends Vue {
  @Prop() private yuiSections: any | YuiSections | undefined
  @Prop() private nextButtonId: any | undefined

  goBack () {
    window.history.back()
  }
}
