
import { Component, Vue } from 'vue-property-decorator'
import VerificationInfoDialog from '@/components/checkout/verification/VerificationDialog.vue'
import axios from 'axios'
import VerificationOK from '@/components/checkout/verification/VerificationOK.vue'
import { UserVerification } from '@/components/checkout/types'
import { mapActions } from 'vuex'
import WebIDVerification from '@/components/checkout/verification/WebID/WebIDVerification.vue'

@Component({
  components: { VerificationOK, WebIDVerification, VerificationInfoDialog },
  computed: {
    ...mapActions({
      updatePaymentData: 'updatePaymentData'
    })
  }
})
export default class UserVerificationMethods extends Vue {
  private verification: any = { verificationMethod: 'UNKNOWN' }
  private showAuthenticationInfo = false

  created () {
    this.updateVerificationData()
  }

  private async updateVerificationData () {
    const paymentData = this.$store.state.paymentData
    await axios.get('/api/checkout/verification-method').then(result => {
      this.verification = result.data
      const userVerification: UserVerification = {
        isVerified: false,
        verificationMethod: result.data.verificationMethod
      }
      if (userVerification.verificationMethod !== 'UNKNOWN') {
        userVerification.isVerified = true
      }
      this.verification.verificationMethod = userVerification.verificationMethod
      paymentData ? paymentData.verification = userVerification : paymentData
      this.$store.state.paymentData = paymentData
      this.$store.dispatch('updatePaymentData', paymentData)
    }).catch(errors => {
      console.log('Could not fetch verification data: ' + errors)
    })
  }
}
