import { render, staticRenderFns } from "./VerificationDialog.vue?vue&type=template&id=2d091a30&scoped=true&"
import script from "./VerificationDialog.vue?vue&type=script&lang=ts&"
export * from "./VerificationDialog.vue?vue&type=script&lang=ts&"
import style0 from "./VerificationDialog.vue?vue&type=style&index=0&id=2d091a30&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d091a30",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VIcon})
