
import { Component, Prop, Vue } from 'vue-property-decorator'
import PrintAndSign from '@/components/filing-documents/documents/print/PrintAndSign.vue'
import DownloadDocuments from '@/components/filing-documents/documents/print/DownloadDocuments.vue'
import FilingNotCompleted from '@/components/filing-documents/FilingNotCompleted.vue'
import DownloadDocumentsElsterI from '@/components/filing-documents/documents/print/DownloadDocumentsElsterI.vue'
import NothingToSend from '@/components/filing-documents/documents/send/NothingToSend.vue'

@Component({
  components: { PrintAndSign, DownloadDocuments, FilingNotCompleted, DownloadDocumentsElsterI, NothingToSend },
})
export default class PrintDocuments extends Vue {
  @Prop({ default: () => [] }) private stateInfo: any | undefined

  @Prop({ default: () => [] }) private tokenName: any | undefined
  @Prop({ default: () => [] }) private csrfToken: any | undefined

  created () {
    this.$store.dispatch('updateCurrentStep', 'filing-print')
  }
}
