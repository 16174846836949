
import { Component, Prop, Vue } from 'vue-property-decorator'
import DownloadButton from '@/components/checkout/base/DownloadButton.vue'
import axios from 'axios'

@Component({
  components: { DownloadButton },
})
export default class DownloadDocuments extends Vue {
  @Prop({ default: () => [] }) private documentsPrinted: any | undefined
  @Prop({ default: () => [] }) private tokenName: any | undefined
  @Prop({ default: () => [] }) private csrfToken: any | undefined

  private pdfUrl = '/printouts/all'
  private buttonText = 'Steuererklärung herunterladen'

  private printed = false

  created () {
    this.printed = this.documentsPrinted
    this.$store.state.nextSectionEnabled = this.printed
    this.pdfUrl = this.pdfUrl + '?skipElster2Pdfs=' + false + '&onlyElster2Pdfs=' + true
  }

  updated () {
    if (this.printed) {
      this.setDocumentsPrinted(this.printed)
    }

    this.$store.state.nextSectionEnabled = this.printed
  }

  setDocumentsPrinted (printed: boolean) {
    axios.post('/elster/saveInterviewSessionParam?printed=' + (printed ? 'true' : 'false'), {}, {})
      // eslint-disable-next-line no-console
      .catch(errors => console.warn(errors))
  }
}
