
import { Vue, Component } from 'vue-property-decorator'
import icons from 'vuetify/src/services/icons/presets/fa'
import Alert from '@/components/checkout/base/Alert.vue'
import VerificationMethodInfoCard from '@/components/checkout/verification/VerificationMethodInfoCard.vue'
@Component({
  methods: {
    icons () {
      return icons
    }
  },
  components: { Alert, VerificationMethodInfoCard }
})
export default class VerificationInfoDialog extends Vue {
}
