
import { Component, Vue } from 'vue-property-decorator'
import PaymentOverview from '@/components/checkout/payment/PaymentOverview.vue'
import CheckoutWebComponent from '@/components/checkout/payment/CheckoutWebComponent.vue'
import LoadingComponent from '@/components/checkout/base/LoadingComponent.vue'
import FilingTaxCase from '@/components/checkout/filing/FilingTaxCase.vue'

@Component({
  components: { PaymentOverview, CheckoutWebComponent, LoadingComponent, FilingTaxCase },
})
export default class CheckoutBody extends Vue {
}
