
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class FinalTile extends Vue {
  @Prop() private tileType: any | undefined
  @Prop() private title: any | undefined
  @Prop() private icon: any | undefined
  @Prop() private description: any | undefined
  @Prop() private action: any | undefined
  @Prop() private url: any | undefined

  private idWhatNext = ''

  created () {
    if (this.tileType === 'what-next') {
      this.idWhatNext = 'ui-filing2-show-what-next'
    }
  }
}
